import React, {useState, useEffect} from 'react';
import {useStripe,Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import config from '../../../config.json';
const PaymentStatus = (props) => {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the PaymentIntent
    stripe
      .retrievePaymentIntent(props.clientSecret)
      .then(({paymentIntent}) => {
        // Inspect the PaymentIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (paymentIntent.status) {
          case 'succeeded':
            setMessage('Success! Payment received. You may close this window now. ');
            break;

          case 'processing':
            setMessage("Payment processing. We'll update you when payment is received.");
            break;

          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            setMessage('Payment failed. Please try another payment method.');
            break;

          default:
            setMessage('Something went wrong.');
            break;
        }
      });
  }, [stripe,props.clientSecret]);


  return (<div style={{marginTop:"50px"}}>{message}</div>);
};
const PaymentResultPage =() =>{
        // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
        'payment_intent_client_secret');
    const PUBLISHABLE_KEY =config.STRIPE_PUBLISHABLE_KEY;
    const [stripePromise, setStripePromise] = useState(() => loadStripe(PUBLISHABLE_KEY));
    const options = {
        // passing the client secret obtained in step 3
        clientSecret: clientSecret?clientSecret:"",
        // Fully customizable with appearance API.
        appearance: {/*...*/},
      };
    return (
        <div style={{marginTop:"50px"}}>
            {clientSecret &&
                <Elements stripe={stripePromise} options={options}>
                <PaymentStatus clientSecret={clientSecret}/>
            </Elements>}
            {!clientSecret && <p2 >Payment Success! A confirmation Email will send to you shortly. You may close this window now!</p2>}
        </div>
    )
}

export default PaymentResultPage;