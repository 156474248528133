import './index.css';
import React, {useEffect, useRef, useState} from "react";
import backdrop from "./image/Backdrop.png";
import tshirtChart from "./image/mcctshirtSize.jpg"
import Button from '@mui/material/Button';
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import {useAuth} from "../../../services/WCAAuth/WCAAuthService";
import {useDispatch, useSelector} from "react-redux";
import {sessionDataActions} from "../../../store";
import {getUserinfo} from "../../../services/AWS/user/user";
import {Auth} from "aws-amplify";
import {json} from "react-router-dom";
import {checkStaffFormSubmitted,submitStaffApplication} from "../../../services/AWS/competitionInformation/competitionInfo";
import config from "../../../config.json";
const localStorageKey = (key) => `groups.${config.WCA_OAUTH_CLIENT_ID}.${key}`;
const getLocalStorage = (key) => localStorage.getItem(localStorageKey(key));
const MastereventList = ["2x2x2", "3x3x3", "4x4x4", "5x5x5", "6x6x6", "7x7x7", "3x3x3 Blindfolded", "3x3x3 Fewest Moves", "3x3x3 One-Handed", "Clock", "Megaminx", "Pyraminx", "Skewb", "Square-1", "4x4x4 Blindfolded", "5x5x5 Blindfolded", "3x3x3 Multi-Blind"];
const PageSection = {
    PERSONAL_INFO: "personalInfo",
    PERSONAL_DETAILS: "personalDetails",
    STAFF_PREFERENCE: "staffPreference",
    REGISTER_SCORE_TAKING: "registerScoreTaking",
    JUDGES: "judges",
    SCRAMBLERS: "scramblers",
    MEDIA: "media",
    LIVESTREAM: "livestream",
    BENEFICIAL: "beneficial"
}

const getUserInfo = (userInfo,propertyName) => {
    if (userInfo === null){
        return "";
    }
    if (userInfo.hasOwnProperty(propertyName)){
        return userInfo[propertyName];
    }
    return "";
}

const getNextSection = (currentSection,selectedRole) => {
    window.scrollTo(0, 0)
    switch (currentSection) {
        case PageSection.PERSONAL_INFO:
            return PageSection.PERSONAL_DETAILS;
        case PageSection.PERSONAL_DETAILS:
            return PageSection.STAFF_PREFERENCE;
        case PageSection.STAFF_PREFERENCE:
           switch (selectedRole) {
                case "Registration":
                     return PageSection.REGISTER_SCORE_TAKING;
                case "Judges":
                     return PageSection.JUDGES;
                case "scramblers":
                     return PageSection.SCRAMBLERS;
                case "Media":
                     return PageSection.MEDIA;
                case "LiveStream":
                     return PageSection.LIVESTREAM;
           }
        case PageSection.REGISTER_SCORE_TAKING:
            return PageSection.BENEFICIAL;
        case PageSection.JUDGES:
            return PageSection.BENEFICIAL;
        case PageSection.SCRAMBLERS:
            return PageSection.BENEFICIAL;
        case PageSection.MEDIA:
            return PageSection.BENEFICIAL;
        case PageSection.LIVESTREAM:
            return PageSection.BENEFICIAL;
        case PageSection.BENEFICIAL:
            return PageSection.BENEFICIAL;
        default:
            return PageSection.PERSONAL_INFO;
    }
}

const getPreviousSection = (currentSection,selectedRole) => {
    window.scrollTo(0, 0)
    switch (currentSection) {
        case PageSection.PERSONAL_INFO:
            return PageSection.PERSONAL_INFO;
        case PageSection.PERSONAL_DETAILS:
            return PageSection.PERSONAL_INFO;
        case PageSection.STAFF_PREFERENCE:
            return PageSection.PERSONAL_DETAILS;
        case PageSection.REGISTER_SCORE_TAKING:
            return PageSection.STAFF_PREFERENCE;
        case PageSection.JUDGES:
            return PageSection.STAFF_PREFERENCE;
        case PageSection.SCRAMBLERS:
            return PageSection.STAFF_PREFERENCE;
        case PageSection.MEDIA:
            return PageSection.STAFF_PREFERENCE;
        case PageSection.LIVESTREAM:
            return PageSection.STAFF_PREFERENCE;
        case PageSection.BENEFICIAL:
            switch (selectedRole) {
                case "Registration":
                    return PageSection.REGISTER_SCORE_TAKING;
                case "Judges":
                    return PageSection.JUDGES;
                case "scramblers":
                    return PageSection.SCRAMBLERS;
                case "Media":
                    return PageSection.MEDIA;
                case "LiveStream":
                    return PageSection.LIVESTREAM;
            }

        default:
            return PageSection.PERSONAL_INFO;
    }
}
const InformationPanel = () => {
    return (
        <div>
        <p>
            Would you like to be a Staff member?
            <br/>
            <br/>
            If you have experience in how a WCA-sanctioned competition runs and would like to help in the Rubik's WCA
            Asian Championship 2024, you are welcome to apply to become a Staff member.
            <br/>
            <br/>
            Candidates with prior experience running a WCA-sanctioned competition will be prioritized.
            <br/>
            <br/>
            <b><u>Qualities needed to become a Staff member:</u></b>
            <br/>
            <li>Integrity</li>
            <li>Responsibility</li>
            <li>Accountability</li>
            <li>Professionalism</li>
            <br/>
            <b><u>Requirements to become a staff member:</u></b>
            <br/>
            <li>Volunteered as staff and competed at least 3 competitions.</li>
            <li>Approval from delegate you selected for vouching.</li>
            <br/>
            <b><u>Benefits of being a staff member:</u></b>
            <br/>
            <li>T-shirt exclusively for Staff members only.</li>
            <li>Special Name Tag & Wristband (Staff exclusive)</li>
            <li>Meals included (Lunch & Dinner).</li>
            <li>Exclusive merchandise.</li>
            <li>Access to Staff Lounge.</li>
            <br/>
            <br/>
            <br/>
            If you are interested in applying as a staff member, please register by filling out this form
            <b><u> before Monday, September 2, 2024 at 8:00 PM GMT+8 </u></b>
            <br/>
            <br/>
            Those shortlisted by (1 October 2024) will receive a confirmation email/Whatsapp message from our team.
            <br/>
            <br/>
            The staff briefing session will be conducted on 31 October 2024 (*May subject to change)
            <br/>
            <br/>
        </p>
        </div>
            )
}

const StaffRegisterPage = () => {
    const {justLoggedIn, signedIn, signIn, signOut, loadingAuth} = useAuth();
    const dispatch = useDispatch();
    const isWCALoggedin = useSelector(state => state.sessionData.isWCALoggedIn);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmittedBefore, setIsSubmittedBefore] = useState(false);
    const [inputs, setInputs] = useState({});
    const [haveError, setHaveError] = useState(false);
    const [errorMessages, setErrorMessages] = useState("");
    const [delegate, setDelegate] = useState('');
    const [JudgeChecked, setJudgeChecked] = useState(false);
    const [error, setError] = useState(false);
    const renderErrorMessage = (name) =>
        name === error.name && (
            <div style={{fontSize:20}} className="error">{error.message}</div>
        );

    const getInputValue = (field) =>{
        if (!inputs.hasOwnProperty(field)){
            return "";
        }
        else
            return inputs[field];
    }

    const signOutWCA = async () => {
        setErrorMessages("Logged Out WCA Account");
        setHaveError(true);
        await signOut();
    }


    const WCALoginPanel = (props) => {
        return (

                <div>
                    <InformationPanel/>
                <Button style={{
                    maxWidth: "200px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    fontSize: 15,
                    borderRadius: "10px"
                }} onClick={() => signIn()} variant="contained">Login with WCA Account</Button>
                </div>
        )
    }
    let InitialListMap = new Map();
    //map eventList to InitalMap with false value
    for (let i = 0; i < MastereventList.length; i++) {
        InitialListMap.set(MastereventList[i], false);
    }
    const [EventToParticipate, setEventToParticipate] = useState(InitialListMap);

    const [selectedDays, setSelectedDay] = useState({
        "30th1421": false,
        "31th1018": false,
        "1Nov1022": false,
        "2Nov1022": false,
        "3Nov1022": false,
        "4Nov1022": false,
        "5Nov1018": false
    });
    const [MealPreference, setMealPreference] = useState('');
    const [TshirtSize, setTshirtSize] = useState('');
    const user = useSelector(state => state.sessionData.userInformation);
    const [Role, setRole] = useState('');
    const [currentSection, setCurrentSection] = useState(PageSection.PERSONAL_INFO);

    useEffect(() => {
        if (isWCALoggedin && user !== null)
        {
            dispatch(sessionDataActions.loading());

            const MYCAToken = getLocalStorage("MYCAaccessToken");
            const isSubmitted = checkStaffFormSubmitted(user.wca_id,"RubiksWCAAsianChampionship2024",MYCAToken).then((response) => {

                if (response.submitBefore) {
                    setIsSubmittedBefore(true);
                } else {
                    setIsSubmittedBefore(false);
                }
                dispatch(sessionDataActions.loaded());

    })}},[isWCALoggedin,user])

    const handleSubmit =async (e) => {
        dispatch(sessionDataActions.loading());
        e.preventDefault();
        if (user == null){
            setErrorMessages("User information is blank!")
            haveError(true);
            return;
        }
        //convert event to participant
        let eventString = "";
        for (const [key, value] of EventToParticipate) {
            if (value) {
                eventString += key + ",";
            }
        }

        //construct the json payload to submit, by combining inputs and other drop downs
        let submitData = {
            ...inputs,
            fullName: user.name,
            email: user.email,
            wca_id: user.wca_id,
            Country: user.country.id,
            dob:user.dob,
            competitionCount:user.competition_count,
            wcaDelegate:delegate,
            selectedDays:selectedDays,
            Role:Role,
            ScamblerEventToParticipate:eventString,
            MealPreference:MealPreference,
            TshirtSize:TshirtSize,
            competition_ID:"RubiksWCAAsianChampionship2024"
        }
        const MYCAToken = getLocalStorage("MYCAaccessToken");
        //submit the form
        const submitRespond = await submitStaffApplication(submitData,MYCAToken)
        if (submitRespond !== "Staff Application Submitted Successfully"){
            setErrorMessages(submitRespond)
            setHaveError(true);
            dispatch(sessionDataActions.loaded());
            return;
        }
        dispatch(sessionDataActions.loaded());
        setIsSubmitted(true);
    }
    const handleSectionSubmit = (e) => {
        e.preventDefault();
        setError({name: "", message: ""});

        if (currentSection === PageSection.STAFF_PREFERENCE) {
            // at lease one day selected
            let isValid = false;
            for (const key in selectedDays) {
                if (selectedDays[key]) {
                    isValid = true;
                    break;
                }
            }
            if (!isValid) {
                setErrorMessages("Please select at least one day to help");
                setError({name: "dateSelection", message: "Please select at least one day to help"});
                setHaveError(true);
                return false;
            }
        }

        if (currentSection === PageSection.SCRAMBLERS){
            let isValid = false;
            for (const [key, value] of EventToParticipate) {
                if (value) {
                    isValid = true;
                    break;
                }
            }
            if (!isValid) {
                setErrorMessages("Please select at least one event to scramble");
                setHaveError(true);
                return false;
            }
        }

        //set form data again to prevent loss

        setCurrentSection(getNextSection(currentSection,Role));
        return ;
    }
    const handleEventsCheckboxChange = (event) => {
        setEventToParticipate(new Map(EventToParticipate.set(event.target.name, event.target.checked)));

    }
    const handleDelegateChange = (event) => {
        setDelegate(event.target.value);
    };
    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };
    const handleClose = (event, reason) => {
        setHaveError(false);
    };
    const handleSelectedDay = (event) => {
        setSelectedDay({
            ...selectedDays,
            [event.target.name]: event.target.checked,
        });
    };
    const handleMealPreference = (event) => {
        setMealPreference(event.target.value);
    }

    const handleTshirtSize = (event) => {
        setTshirtSize(event.target.value);
    }

    const personalInfoSection = (
        <form onSubmit={handleSectionSubmit}>
            <div className="input-container">
                <p><b>Full Name </b></p>
                <InputLabel id="emailValue">{user && user.name}</InputLabel>
            </div>
            <div className="input-container">
                <p><b>Email </b></p>
                <InputLabel id="emailValue">{user && user.email}</InputLabel>
            </div>
            <div className="input-container">
                <p><b>WCA ID </b></p>
                <InputLabel id="emailValue">{user && user.wca_id}</InputLabel>
            </div>
            <div className="input-container">
                <p><b>Region </b></p>
                <InputLabel id="emailValue">{user && user.country.id}</InputLabel>
            </div>
            <div className="input-container">
                <p><b>Date Of Birth </b></p>
                <InputLabel id="emailValue">{user && user.dob}</InputLabel>
            </div>
            <div className="input-container">
                <p><b>Competition Participated </b></p>
                <InputLabel id="emailValue">{user && user.competition_count}</InputLabel>
            </div>
            <br/>
            <Button variant="outlined" style={{height: "30px"}} onClick={signOutWCA}>Log Out
                WCA</Button>
            <div className="input-container">
                <p><b>Listed WCA delegate that can vouch for you? </b></p>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={delegate}
                    onChange={handleDelegateChange}
                    label="Delegate"
                    required
                >
                    <MenuItem value="Kim">Chan Tak Chuen (陳德泉)</MenuItem>
                    <MenuItem value="Richard">Chia-Leo Lin (林珈樂)</MenuItem>
                    <MenuItem value="Fanyuan">Fangyuan Chang (常方圆)</MenuItem>
                    <MenuItem value="Wanghao">Hao Wang (王皓)</MenuItem>
                    <MenuItem value="ZiYang">Hoe Zi Yang</MenuItem>
                    <MenuItem value="Ivan">Ivan Lew Yi Wen (刘义文)</MenuItem>
                    <MenuItem value="Yijun">Leow Yi Jun (廖艺畯)</MenuItem>
                    <MenuItem value="ZhengMing"> Ming Zheng (郑鸣)</MenuItem>
                    <MenuItem value="Junwen">Wang Junwen (王俊文)</MenuItem>
                    <MenuItem value="Wilson">Wilson Alvis (陈智胜)</MenuItem>
                    <MenuItem value="Yichen">Zhou Yichen (周奕臣)</MenuItem>
                </Select>
            </div>
            <Box sx={{display: 'flex', justifyContent: 'flex-start', gap: 2}}>
                {user && user.competition_count >= 3 &&  <Button variant="contained" color="primary" type="submit">
                    Next
                </Button>}
                {user && user.competition_count < 3 && <div style={{fontSize:20}} className="error">You have to participated in more than 3 competition before in order to apply!</div>}
            </Box>

        </form>
    )

    const PersonalDetailsSection = (
        <form onSubmit={handleSectionSubmit}>
            <div className="input-container">
                <p><b>Contact Number (Whatsapp) [Country Code] + [Phone Number]* </b></p>
                <TextField name="PhoneNumber" label="e.g. 6012345632" type="number" required value={getInputValue("PhoneNumber")}
                           onChange={(event) => handleFormChange(event, "PhoneNumber")}/>
            </div>
            <div className="input-container">
                <p><b>Introduce yourself (Past staffing experience)*</b></p>
                <TextField name="introduction" label="Self Introduction" type="txt" required
                           multiline={true} rows={4}
                           variant="outlined" value={getInputValue("introduction")}
                           onChange={(event) => handleFormChange(event, "introduction")}/>
            </div>
            <div className="input-container">
                <p><b>Have you ever organized a WCA Competition? List Down If have.</b></p>
                <TextField name="PreviousOrgCompetitions" label="Competitions" type="text" multiline={true}
                           rows={4}
                           variant="outlined" value={getInputValue("PreviousOrgCompetitions")}
                           onChange={(event) => handleFormChange(event, "PreviousOrgCompetitions")}/>
            </div>
            <div className="input-container">
                <p><b>Have volunteered as staff at any WCA competition before?, if yes, List out the
                    competitions</b></p>
                <TextField name="PreviousHelpedCompetitions" label="Competitions" type="text"
                           multiline={true} rows={4}
                           variant="outlined" value={getInputValue("PreviousHelpedCompetitions")}
                           onChange={(event) => handleFormChange(event, "PreviousHelpedCompetitions")}/>
            </div>
            <Box sx={{display: 'flex', justifyContent: 'flex-start', gap: 2}}>
                <Button variant="contained" color="primary"
                        onClick={() => setCurrentSection(getPreviousSection(currentSection, Role))}>
                    Back
                </Button>
                <Button variant="contained" color="primary" type="submit">
                    Next
                </Button>
            </Box>
        </form>)
    const StaffPreferenceSection = (
        <form onSubmit={handleSectionSubmit}>
            <div className="input-container">
                <p><b>Which of the following day(s) could you help?
                </b></p>
                <FormGroup style={{fontSize: "10px"}} required>
                    <FormControlLabel
                        control={
                            <Checkbox checked={selectedDays["30th1421"]} onChange={handleSelectedDay}

                                      name="30th1421"/>
                        }
                        label="2.00 PM to 9.00 PM, 30th October 2024 (Setup day)"
                    />
                    <br/>
                    <FormControlLabel
                        control={
                            <Checkbox checked={selectedDays["31th1018"]} onChange={handleSelectedDay}
                                      name="31th1018"/>
                        }
                        label="10.00 AM to 6.00 PM, 31st October 2024 (Setup & Training Day) (**Mandatory to attend for all except media)
"
                    />
                    <br/>
                    <FormControlLabel
                        control={
                            <Checkbox checked={selectedDays["1Nov1022"]} onChange={handleSelectedDay}
                                      name="1Nov1022"/>
                        }
                        label="10.00AM to 10.00 PM, 1st November 2024 (**Mandatory to attend for all)"
                    />
                    <br/>
                    <FormControlLabel
                        control={
                            <Checkbox checked={selectedDays["2Nov1022"]} onChange={handleSelectedDay}
                                      name="2Nov1022"/>
                        }
                        label="10.00 AM to 10.00PM, 2nd November 2024 (**Mandatory to attend for all)"
                    />
                    <br/>
                    <FormControlLabel
                        control={
                            <Checkbox checked={selectedDays["3Nov1022"]} onChange={handleSelectedDay}
                                      name="3Nov1022"/>
                        }
                        label="10.00 AM to 10.00PM, 3rd November 2024 (**Mandatory to attend for all)"
                    />
                    <br/>
                    <FormControlLabel
                        control={
                            <Checkbox checked={selectedDays["4Nov1022"]} onChange={handleSelectedDay}
                                      name="4Nov1022"/>
                        }
                        label="10.00 AM to 10.00PM, 4th November 2024 (*mandatory for media)"
                    />
                    <br/>
                    <FormControlLabel
                        control={
                            <Checkbox checked={selectedDays["5Nov1018"]} onChange={handleSelectedDay}
                                      name="5Nov1018"/>
                        }
                        label="10.00 AM to 6.00PM, 5th November (Dismantling Day)"
                    />
                    <br/>
                </FormGroup>
                {renderErrorMessage("dateSelection")}
                <div className="input-container">
                    <p><b>Which role do you want to apply for?*</b></p>
                    <Select
                        value={Role}
                        onChange={handleRoleChange}
                        label="Role"
                        required
                    >
                        <MenuItem value="Registration">Registration & Score-taking</MenuItem>
                        <MenuItem value="Judges">Judges & Runners</MenuItem>
                        <MenuItem value="scramblers">Scrambling</MenuItem>
                        <MenuItem value="Media">Media</MenuItem>
                        <MenuItem value="LiveStream">Livestream broadcasting</MenuItem>
                    </Select>
                </div>
            </div>
            <Box sx={{display: 'flex', justifyContent: 'flex-start', gap: 2}}>
                <Button variant="contained" color="primary"
                        onClick={() => setCurrentSection(getPreviousSection(currentSection, Role))}>
                    Back
                </Button>
                <Button variant="contained" color="primary" type="submit">
                    Next
                </Button>
            </Box>
        </form>
    )
    const RegisterAndScoreTakingSection = (
        <form onSubmit={handleSectionSubmit}>
            <h2>Roles - Registration / Score-taking</h2>
            <p>
                We need a team consisting of 6 people to run shifts during the competition for the registration of
                competitors and score-keeping of the results.
                <br/>
                <br/>
                Skills required:
                <br/>
                <li>Proficiency in English and Mandarin (Chinese) with any other languages will be prioritised.</li>
                <li>Proficiency in typing accuracy will be highly appreciated.</li>
                <li>Meticulous and attention to details.</li>
            </p>
            <div className="input-container">
                <p><b>Typing Test (Word Per Minutes)</b></p>
                <a href="https://onlinetyping.org/10-key-typing-test/10-key.php" target="_blank">
                    https://onlinetyping.org/10-key-typing-test/10-key.php

                </a>
                <TextField name="typeWPM" label="WPM" type="number" required value={getInputValue("typeWPM")}
                           variant="outlined" onChange={(event) => handleFormChange(event, "typeWPM")}/>
            </div>
            <Box sx={{display: 'flex', justifyContent: 'flex-start', gap: 2}}>
                <Button variant="contained" color="primary"
                        onClick={() => setCurrentSection(getPreviousSection(currentSection, Role))}>
                    Back
                </Button>
                <Button variant="contained" color="primary" type="submit">
                    Next
                </Button>
            </Box>
        </form>
    )

    const JudgesAndRunnerSection = (
        <form onSubmit={handleSectionSubmit}>
            <h2>Roles - Judges</h2>
            <div className="input-container">
                <p>
                    We need a team of people to run shifts during the competition to be the judge of every solving
                    station allocated.
                    <br/>
                    <br/>
                    Please watch the video below to understand how the solving station works.
                    <br/>
                    <br/>
                    Skills required:
                    <br/>
                    <li>Proficiency in English and Mandarin (Chinese) with any other languages will be prioritised.</li>
                    <li>Meticulous and attention to details.</li>
                    <li>Staff at previous major championship will be highly prioritised.</li>
                    <br/>
                    <br/>
                    You will be given a briefing about your job scope once you are shortlisted.
                </p>
                <h4>WCA Competition Tutorial by Speedcubing Australia</h4>
                <iframe width="100%" src="https://www.youtube.com/embed/BUZ4hZ_40Q0"
                        height={"500px"}
                        title="How To Judge At A Speedcubing Competition (Part III)" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                <FormControlLabel
                    control={
                        <Checkbox required
                                    checked={JudgeChecked}
                                  onChange={(event) => setJudgeChecked(event.target.checked)}
                                  name="six"/>
                    }
                    label="I have watched the WCA Competition Tutorial."
                />
            </div>
            <Box sx={{display: 'flex', justifyContent: 'flex-start', gap: 2}}>
                <Button variant="contained" color="primary"
                        onClick={() => setCurrentSection(getPreviousSection(currentSection, Role))}>
                    Back
                </Button>
                <Button variant="contained" color="primary" type="submit">
                    Next
                </Button>
            </Box>
        </form>
    )

    const ScramblersSection = (
        <form onSubmit={handleSectionSubmit}>
            <h2>Roles - Scramblers</h2>
            <div className="input-container">
                <p>
                    We need a team of people to run shifts during the competition to scramble the cubes for the
                    competitors.
                    <br/>
                    <br/>
                    Skills required:
                    <br/>
                    <li>Top 100 in Psych Sheet for selected event.</li>
                    <li>Meticulous and attention to details.</li>
                    <li>Staff at previous major championship will be highly prioritised.</li>
                    <li>Efficiency and accuracy is the key.</li>
                    <br/>
                    <br/>
                    You will be given a briefing about your job scope once you are shortlisted.
                </p>

                <h3>Which events would you are competent in scrambling?
                    *</h3>
                {MastereventList && MastereventList.map((item) => (
                    <EventListSelection event={item} EventToParticipate={EventToParticipate}
                                        handleEventsCheckboxChange={handleEventsCheckboxChange}/>
                ))}

            </div>
            <Box sx={{display: 'flex', justifyContent: 'flex-start', gap: 2}}>
                <Button variant="contained" color="primary"
                        onClick={() => setCurrentSection(getPreviousSection(currentSection, Role))}>
                    Back
                </Button>
                <Button variant="contained" color="primary" type="submit">
                    Next
                </Button>
            </Box>
        </form>
    )

    const MediaSection = (
        <form onSubmit={handleSectionSubmit}>
            <h2>Roles - Media</h2>
            <p>
                Requirement
                <br/>
                <br/>
                <li>Proof of affiliation with recognised media outlet or platform (e.g Business Card, official email)
                </li>
                <li>Proficiency with professional-grade cameras, audio recording devices, and other necessary media
                    equipment.
                </li>
                <li>Adhering to the tournament's guidelines and respecting the space and privacy of competitors and
                    organizers.
                </li>
                <li>Skills in capturing high-quality photos, videos, and interviews.</li>
                <li>Total Followers across all social medial platform: > 500,000</li>
                <li>Average view count: 100,000 views per video/post</li>
                <br/>
                <br/>
                You will be given a briefing about your job scope once you are shortlisted.
            </p>
            <h3>Social Media Account Link:
                *</h3>
            <p>(i.e:
                <br/>
                Youtube: https://www.youtube.com/@malaysiacubesportsassociation
                <br/>
                Facebook: https://www.facebook.com/mycubeassociation
                <br/>
                Instagram: https://instagram.com/mycubeassociation</p>
            <TextField name="SocialMediaLink" label="Link:" type="text"
                       style={{width: "100%"}} required
                       multiline={true} rows={4} value={getInputValue("SocialMediaLink")}
                       variant="outlined" onChange={(event) => handleFormChange(event, "SocialMediaLink")}/>
            <Box sx={{display: 'flex', justifyContent: 'flex-start', gap: 2}}>
                <Button variant="contained" color="primary"
                        onClick={() => setCurrentSection(getPreviousSection(currentSection, Role))}>
                    Back
                </Button>
                <Button variant="contained" color="primary" type="submit">
                    Next
                </Button>
            </Box>
        </form>
    )

    const liveStreamSection = (
        <form onSubmit={handleSectionSubmit}>
            <h2>Roles - Livestream Broadcasting</h2>
            <p>
                Requirement
                <br/>
                <br/>
                <li>Skill in interacting with viewers through chat, polls and social media</li>
                <li>Capability to proivde engaging and informative live commentary</li>
                <li>Strong coordination with with commentators, participants and livestream cameraman</li>
                <li>Familiar with top competitors and their profiles to provide insightful commentary</li>
                <li>Proficiency in interviewing, scripting, manage transition during the broadcast.</li>
                <br/>
                <br/>
                You will be given a briefing about your job scope once you are shortlisted.
            </p>
            <h3>Social Media Account Link:</h3>
            <TextField name="SocialMediaLink" label="Link:" type="text"
                       style={{width: "100%"}} required
                       multiline={true} rows={4} value={getInputValue("SocialMediaLink")}
                       variant="outlined" onChange={(event) => handleFormChange(event, "SocialMediaLink")}/>
            <Box sx={{display: 'flex', justifyContent: 'flex-start', gap: 2}}>
                <Button variant="contained" color="primary"
                        onClick={() => setCurrentSection(getPreviousSection(currentSection, Role))}>
                    Back
                </Button>
                <Button variant="contained" color="primary" type="submit">
                    Next
                </Button>
            </Box>
        </form>)
    const BeneficialSection = (
        <form onSubmit={handleSubmit}>
            <h2>Benefits in return</h2>
            <p>
                We highly appreciate your support in volunteering to become a staff member, meanwhile, we
                would like to express our gratitude with some gifts in return listed below.
            </p>
            <br/>
            <b><u>Benefits of being a staff member:</u></b>
            <br/>
            <li>T-shirt exclusively for Staff members only.</li>
            <li>Special Name Tag & Wristband (Staff exclusive)</li>
            <li>Meals included (Lunch & Dinner).</li>
            <li>Exclusive merchandise.</li>
            <li>Access to Staff Lounge.</li>
            <br/>
            <br/>
            <img src={tshirtChart} alt="tshirtChart" className="tshirtChart"/>
            <br/>
            <div className={"input-container"}>
                <p><b>What is your T-shirt size? (refer to the chart above)</b></p>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={TshirtSize}
                    onChange={event => handleTshirtSize(event)}
                    label="Size"
                    required={true}
                >
                    <MenuItem value={'XS'}>XS</MenuItem>
                    <MenuItem value={'S'}>S</MenuItem>
                    <MenuItem value={'M'}>M</MenuItem>
                    <MenuItem value={'L'}>L</MenuItem>
                    <MenuItem value={'XL'}>XL</MenuItem>>
                    <MenuItem value={'2XL'}>2XL</MenuItem>
                    <MenuItem value={'3XL'}>3XL</MenuItem>
                    <MenuItem value={'4XL'}>4XL</MenuItem>
                    <MenuItem value={'5XL'}>5XL</MenuItem>

                </Select>
            </div>
            <div className={"input-container"}>
                <p><b>Meals Options*</b></p>
                <p>Please let us know if you have any food allergies or dietary restrictions.*</p>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={MealPreference}
                    onChange={handleMealPreference}
                    label="Size"
                    required={true}
                >
                    <MenuItem value={'meat'}>Meat-eater 葷食</MenuItem>
                    <MenuItem value={'Vegetarian'}>Vegetarian 素食</MenuItem>
                    <MenuItem value={'Halal'}>Halal 清真</MenuItem>
                    <MenuItem value={'Pescatarian'}>Pescatarian 鱼素者</MenuItem>
                    <MenuItem value={'Pescatarian'}>Others</MenuItem>
                </Select>
                <TextField name="OtherFood" label="Others food Preference:" type="text" value={getInputValue("OtherFood")}
                           variant="outlined" onChange={(event) => handleFormChange(event, "OtherFood")}/>
            </div>
            <div className="input-container">
                <p><b>Additional comments / Remark for your application </b></p>
                <TextField name="remark" label="Your Answer" type="text" value={getInputValue("remark")}
                           variant="outlined" onChange={(event) => handleFormChange(event, "remark")}/>
            </div>

            <div className="input-container">
                <p><b>One last thing:</b></p>
                <FormGroup style={{fontSize: "10px"}}>
                    <FormControlLabel
                        control={
                            <Checkbox required={true}
                                      name="six"/>
                        }
                        label="I understand that not showing up without notice to the organizational team will result in being blacklisted from staffing list."
                    />
                    <br/>
                    <FormControlLabel
                        control={
                            <Checkbox required={true}
                                      name="six"/>
                        }
                        label="If selected, I agree to follow the directions of the Delegate, Organiser and other staff, and to focus on staffing the competition. I will warm up for events as needed, but be considerate of my responsibilities."
                    />
                    <br/>
                    <FormControlLabel
                        control={
                            <Checkbox required={true}
                                      name="six"/>
                        }
                        label="I verily agree that the information provided are true and shall adhere to the rules and regulations provided by WCA and also the organising team."
                    />
                    <br/>
                </FormGroup>

            </div>

            <div className="input-container">
                <Button className="button" variant="contained" type="submit">Submit</Button>
            </div>

            <Box sx={{display: 'flex', justifyContent: 'flex-start', gap: 2}}>
                <Button variant="contained" color="primary"
                        onClick={() => setCurrentSection(getPreviousSection(currentSection, Role))}>
                    Back
                </Button>
            </Box>
        </form>)

    const renderForm = (
        <div className="app">

            {currentSection === PageSection.PERSONAL_INFO && personalInfoSection}
            {currentSection === PageSection.PERSONAL_DETAILS && PersonalDetailsSection}
            {currentSection === PageSection.STAFF_PREFERENCE && StaffPreferenceSection}
            {currentSection === PageSection.REGISTER_SCORE_TAKING && RegisterAndScoreTakingSection}
            {currentSection === PageSection.JUDGES && JudgesAndRunnerSection}
            {currentSection === PageSection.SCRAMBLERS && ScramblersSection}
            {currentSection === PageSection.MEDIA && MediaSection}
            {currentSection === PageSection.LIVESTREAM && liveStreamSection}
            {currentSection === PageSection.BENEFICIAL && BeneficialSection}

        </div>

    )
    const action = (
        <>
            <Button color="secondary" size="small" onClick={handleClose}>
            Close
            </Button>
        </>
    );

    const handleFormChange = (event, name) => {

        let value = event.target.value;
        //sanitize input, not allow character breaking json, allow comma,space, period
        value = value.replace(/[^a-zA-Z0-9 ,.]/g, '');


        setInputs(values => ({...values, [name]: value}))
    }

    return (
        <div className="app">
            <div className="register-form">
                <img src={backdrop} alt="backdrop" className="backdrop"/>
                <div className="title"><b>Rubik's WCA Asian Championship 2024 <br/> Staff Registration</b></div>
                {!isSubmitted && !isSubmittedBefore && (!isWCALoggedin ? <WCALoginPanel/> : <div>{renderForm}</div>
                )}
                {isSubmitted &&
                    <div className="submitted">Your application has been submitted successfully! <br/> You will be
                        contacted if you been shortlisted.<br/> You may close this window now</div>}
                {isSubmittedBefore &&
                    <div className="submitted">You have submitted the form before! We will contact you if you're being
                        shortlisted, thank you! <br/> <Button variant="outlined" style={{height: "30px"}}
                                                              onClick={signOutWCA}>Log Out
                            WCA</Button></div>}
            </div>
            <Snackbar
                open={haveError}
                autoHideDuration={6000}
                onClose={handleClose}
                message={errorMessages}
                action={action}
            />
        </div>
    )
}

export default StaffRegisterPage;

//todo: auto log are you competing in the competition with wca login
const EventListSelection = (props) => {
    return (
        <FormControl>
            <FormControlLabel
                checked={props.EventToParticipate.get(props.event)}
                key={props.event} name={props.event}
                onChange={props.handleEventsCheckboxChange}
                control={<Checkbox
                    style={{color: "#00e676",}}/>}
                label={props.event}/>
        </FormControl>
    )
}





